import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import { RatioContext, realPx, contactUs } from '../../static/tools/common';
import './footer.css';
import logo from '../../static/images/opt_home_logo.png';
import facebook from '../../static/images/facebook@2x.png';
import insta from '../../static/images/insta@2x.png';
import tiktok from '../../static/images/tiktok@2x.png';
import twitter from '../../static/images/twitter@2x.png';
import youtube from '../../static/images/youtube@2x.png';
import footemail from '../../static/images/opt_home_footemail.png';

function Index() {
    const navigator = useNavigate();
    const ratio = useContext(RatioContext) || 1;
    const pathname = useLocation().pathname;
    const style = {
        footerItem1: {
            height: realPx(ratio, 320),
            paddingTop: realPx(ratio, 80),
            paddingLeft: realPx(ratio, 360),
        },
        footerItem2: {
            paddingLeft: realPx(ratio, 360),
            height: realPx(ratio, 72),
            lineHeight: realPx(ratio, 72),
            color: 'rgba(255, 255, 255, 1)',
            fontSize: realPx(ratio, 12)
        },
        footerItem1img: {
            width: realPx(ratio, 130),
        },
        footerItem1text: {
            width: realPx(ratio, 241),
            marginTop: realPx(ratio, 24),
            fontSize: realPx(ratio, 14),
            lineHeight: realPx(ratio, 24),
            color: 'rgba(255, 255, 255, 1)'
        },
        footerh1: {
            fontSize: realPx(ratio, 22),
            lineHeight: realPx(ratio, 38),
            fontWeight: 'blod',
            color: 'rgba(255, 255, 255, 1)'
        },
        footerNav: {
            marginTop: realPx(ratio, 8),
            fontSize: realPx(ratio, 14),
            lineHeight: realPx(ratio, 28),
            color: 'rgba(255, 255, 255, 1)',
        },
        location: {
            width: realPx(ratio, 160),
            fontSize: realPx(ratio, 14),
            lineHeight: realPx(ratio, 24),
            fontWeight: 300,
            color: 'rgba(255, 255, 255, 1)',
        },
        iconMargin: {
            marginLeft: realPx(ratio, 24),
        },
        tiktok: {
            width: realPx(ratio, 22),
            height: realPx(ratio, 26),
        },
        youtube: {
            width: realPx(ratio, 27),
            height: realPx(ratio, 19),
        },
        insta: {
            width: realPx(ratio, 23),
            height: realPx(ratio, 23),
        },
        facebook: {
            width: realPx(ratio, 27),
            height: realPx(ratio, 26),
        },
        twitter: {
            width: realPx(ratio, 26),
            height: realPx(ratio, 21),
        },
        emailBtn: {
            padding: `0 ${realPx(ratio, 20)}`,
            width: realPx(ratio, 302),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontWeight: 'bold'
        },
        emailBtnImg: {
            width: realPx(ratio, 44),
            height: realPx(ratio, 44),
            borderRadius: '50%',
        }
    }
    const navigatorPrev = (path: string) => {
        if (pathname === path) {
            return;
        }
        navigator(path);
    }
    return (
        <div className="footer">
            <div style={style.footerItem1}>
                <div>
                    <img className='wow animate__fadeInLeftBig' style={style.footerItem1img} src={logo} alt="icon" />
                    <div className='wow fadeInLeftBig1' style={style.footerItem1text}>Your gateway to effective advertising solutions, designed to bring your brand closer to your target audience.</div>
                </div>
                <div className='wow animate__flipInY footer-nav' style={{ marginLeft: realPx(ratio, 101) }}>
                    <div style={style.footerh1}>Fuction</div>
                    <div onClick={() => navigatorPrev('/')} className='custom-card boottom-nav' style={{ ...style.footerNav, width: realPx(ratio, 44), marginTop: realPx(ratio, 15) }}>Home</div>
                    <div onClick={() => navigatorPrev('/UserAcquisiton')} className='custom-card boottom-nav' style={{ ...style.footerNav, width: realPx(ratio, 59) }}>Solution</div>
                    <div onClick={() => navigatorPrev('/Research')} className='custom-card boottom-nav' style={{ ...style.footerNav, width: realPx(ratio, 65) }}>Research</div>
                    <div onClick={() => navigatorPrev('/About')} className='custom-card boottom-nav' style={{ ...style.footerNav, width: realPx(ratio, 44) }}>About</div>
                </div>
                <div className='wow animate__flipInY' style={{ marginLeft: realPx(ratio, 124) }}>
                    <div style={style.footerh1}>Location</div>
                    <div style={{...style.location, marginTop: realPx(ratio, 16),}}>UNIT 225-22, 2/F MEGA, CUBE 8,</div>
                    <div style={style.location}>WANG KWONG RD,</div>
                    <div style={style.location}>KLN BAY,</div>
                    <div style={style.location}>HONG KONG</div>
                    <div style={{ ...style.location, marginTop: realPx(ratio, 35) }}>business@opt360.net</div>
                </div>
                <div style={{ marginLeft: realPx(ratio, 168) }}>
                    <div className='wow animate__fadeInRightBig' style={{ ...style.footerh1, marginBottom: realPx(ratio, 12) }}>Contact us</div>
                    {/* <a href="mailTo:business@opt360.net"> */}
                    {
                        pathname === '/About' ? (
                            <Link to="touchUs" smooth duration={500}>
                                <div className='wow fadeInRightBig1 email-btn' onClick={() => contactUs(navigator)}>
                                    <div className='custom-btn-hover' style={style.emailBtn}>
                                        <span>Email</span>
                                        <img className='custom-btn-hover-img' style={style.emailBtnImg} src={footemail} alt="icon" />
                                    </div>
                                </div>
                            </Link>
                        ) : (
                            <div className='wow fadeInRightBig1 email-btn' onClick={() => contactUs(navigator)}>
                                <div className='custom-btn-hover' style={style.emailBtn}>
                                    <span>Email</span>
                                    <img className='custom-btn-hover-img' style={style.emailBtnImg} src={footemail} alt="icon" />
                                </div>
                            </div>
                        )
                    }
                    {/* </a> */}
                    <div className='wow fadeInRightBig2' style={{ marginTop: realPx(ratio, 43) }}>
                        <img style={style.tiktok} src={tiktok} alt="tiktok" />
                        <img style={{ ...style.youtube, ...style.iconMargin }} src={youtube} alt="youtube" />
                        <img style={{ ...style.insta, ...style.iconMargin }} src={insta} alt="insta" />
                        <img style={{ ...style.facebook, ...style.iconMargin }} src={facebook} alt="facebook" />
                        <img style={{ ...style.twitter, ...style.iconMargin }} src={twitter} alt="twitter" />
                    </div>
                </div>
            </div>
            <div className='wow zoomInLeft1' style={style.footerItem2}>
                <div>© 2024 OPT 360 limited, All Rights Reserved</div>
            </div>
        </div>
    )
}

export default Index;